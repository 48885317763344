<template>
  <div>
    <div>
      <div class="container-fluid p-0">
        <div class="row">
          <div class="col-12">
            <div class="login-card" style="display: block;">
              <div>
                <div>
                  <a class="logo">
                    <img
                      class="img-fluid for-light"
                      src="@/assets/images/PSICOMETRIX-logo.jpg"
                      alt="looginpage"
                      width="190px"
                    />
                    <img
                      class="img-fluid for-dark"
                      src="@/assets/images/logo/logo_dark.png"
                      alt="looginpage"
                    />
                  </a>
                </div>

                <div class="login-main login-form-card font-unv shadow">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="alert alert-danger font-unv" role="alert">
                        <h4 class="alert-heading">{{title}}</h4>
                        <p class="font-unv">
                          Error: <b>{{code}}</b> 
                        </p>
                        <p class="mt-2">
                          {{text}}
                        </p>
                        <p class="mt-2 font-unv">
                          "Ha ocurrido un error con los datos enviados a la pasarela de pago. Por favor, verifica la información y vuelve a intentarlo. Si el problema persiste, contacta con atención al cliente en pgarimani@psicometrix.cl."
                        </p>
                     
                      </div>
                    </div>

                    <p class="mt-4 mb-0 font-unv">
                        ¿Quieres realizar un nuevo registro?

                        <router-link class="ml-2" tag="a" to="/">
                          Click aqui
                        </router-link>
                      </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Error",
  data() {
    return {
      title: '',
      text: '',
      text_extend: 'Pronto recibirás las instrucciones para realizar la evaluación.',
      text_extend_two: '',
      code: '',
      signature: 'PSICOMETRIX CHILE SPA'
    };
  },
  methods: {
    setmessage(code, order){
        this.text = order
        this.code = code;
    }
  },
  created() {
    const code = this.$route.query.error;
    const order = this.$route.query.message;
    this.setmessage(code, order);
  },
};
</script>
<style scoped>

.alert-danger {
  background-color: rgba(238, 190, 195, 0.8) !important;
  border-color: rgba(220, 178, 182, 0.9);
  color: rgba(184, 48, 62, 0.9);
}
</style>